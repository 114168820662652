<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-card>
        <v-layout
          class="d-flex flex-lg-row flex-md-column flex-sm-column"
          wrap
          pa-3
        >
          <v-flex lg12 md12 sm12 xs12>
            <v-text-field
              v-model="selectedItem.Name"
              label="Tên bảng giá"
              class="input-group--focused"
            ></v-text-field>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <v-select
              label="Trạng thái"
              v-model="selectedItem.Status"
              :items="prices_status_list"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-flex>
          <v-flex lg12 md12 sm12 xs12>
            <v-select
              label="Phân loại"
              v-model="selectedItem.Global"
              :items="prices_global_list"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-card>
      <v-card>
        <v-tabs v-model="tab" light right icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            Phí cân nặng
            <v-icon>fa-balance-scale</v-icon>
          </v-tab>

          <!-- <v-tab href="#tab-2">
            Phí khối
            <v-icon>fa-box</v-icon>
          </v-tab> -->

          <v-tab href="#tab-3" v-if="selectedItem.Global == prices_global.private">
            Khách hàng
            <v-icon>fa-users</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-flex lg12 md12 sm12 xs12 pt-2 pl-3>
                <v-btn @click="dialogNewFeeWeight = true">Thêm mới</v-btn>
              </v-flex>
              <feeweight-list :priceId="modifyId"></feeweight-list>
            </v-card>
          </v-tab-item>
          <!-- <v-tab-item value="tab-2">
            <v-card flat>
              <v-flex lg12 md12 sm12 xs12 pt-2 pl-3>
                <v-btn @click="dialogNewFeeVolume = true">Thêm mới</v-btn>
              </v-flex>
              <feevolume-list :priceId="modifyId"></feevolume-list>
            </v-card>
          </v-tab-item> -->
          <v-tab-item value="tab-3">
            <v-card flat>
                <v-layout row wrap pa-2>
                    <v-flex lg4 md4 sm12 pl-4>
                        <v-autocomplete label="Khách hàng" v-model="selectedCustomerID" :items="customers" item-text="FilterName" item-value="ID"></v-autocomplete>
                    </v-flex>
                    <v-flex lg4 md4 sm12 pt-8>
                        <v-btn @click="onUpdateCustomer">Thêm mới</v-btn>
                    </v-flex>
                </v-layout>
              <customer-list :priceId="modifyId"></customer-list>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogNewFeeWeight" persistent max-width="500px">
      <v-card>
            <v-card-title>
                <span class="title">Phí cân nặng</span>
            </v-card-title>
            <v-card-text>
                <v-layout row wrap pa-3>
                    <v-flex lg6 md6 sm12>
                      <v-select label="Loại hàng" v-model="feeWeight.ProductType" :items="product_type_list" item-text="name" item-value="value" clearable></v-select>
                    </v-flex>
                    <v-flex lg6 md6 sm12 pl-2>
                        <v-select label="Kho VN" v-model="feeWeight.ReceivePlaceID" :items="stores" item-text="Name" item-value="ID" clearable></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                        <h-currency-input v-model="feeWeight.WeightFrom" :decimal="1" label="Cân nặng (Min)" class="input-group--focused"></h-currency-input>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                        <h-currency-input v-model="feeWeight.WeightTo" :decimal="1" label="Cân nặng (Max)" class="input-group--focused"></h-currency-input>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                        <h-currency-input v-model="feeWeight.Amount" :decimal="0" label="Cước cân" class="input-group--focused"></h-currency-input>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                        <h-currency-input v-model="feeWeight.AmountVolume" :decimal="0" label="Cước khối" class="input-group--focused"></h-currency-input>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogNewFeeWeight = false">Hủy</v-btn>
                <v-btn color="blue darken-1" text @click="onCreateFeeWeight">Đồng ý</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewFeeVolume" persistent max-width="500px">
      <v-card>
            <v-card-title>
                <span class="title">Phí khối</span>
            </v-card-title>
            <v-card-text>
                <v-layout row wrap pa-3>
                    <v-flex lg6 md6 sm12>
                      <v-select label="Loại hàng" v-model="feeVolume.ProductType" :items="product_type_list" item-text="name" item-value="value" clearable></v-select>
                    </v-flex>
                    <v-flex lg6 md6 sm12 pl-2>
                      <v-select label="Kho VN" v-model="feeVolume.ReceivePlaceID" :items="stores" item-text="Name" item-value="ID" clearable></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                      <h-currency-input v-model="feeVolume.VolumeFrom" :decimal="1" label="Khối (Min)" class="input-group--focused"></h-currency-input>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                      <h-currency-input v-model="feeVolume.VolumeTo" :decimal="1" label="Khối (Max)" class="input-group--focused"></h-currency-input>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                      <h-currency-input v-model="feeVolume.Amount" :decimal="0" label="Tiền phí" class="input-group--focused"></h-currency-input>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogNewFeeVolume = false">Hủy</v-btn>
                <v-btn color="blue darken-1" text @click="onCreateFeeVolume">Đồng ý</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import configs from "../../configs";
import feeweight_list from "../FeeWeight/list";
// import feevolume_list from "../FeeVolume/list";
import customer_list from "../Customer/CustomerList";
import { mapState } from "vuex";
export default {
  components: {
    "feeweight-list": feeweight_list,
    // "feevolume-list": feevolume_list,
    "customer-list": customer_list
  },
  data() {
    return {
      prices_global_list: configs.PRICES_GLOBAL_LIST,
      prices_global: configs.PRICES_GLOBAL,
      prices_status_list: configs.PRICES_STATUS_LIST,
      tab: null,
      dialogNewFeeWeight: false,
      dialogNewFeeVolume: false,
      selectedCustomerID: 0,
      feeWeight: {
          ID: 0,
          ReceivePlaceID: 0,
          WeightFrom: 0,
          WeightTo: 0,
          Amount: 0
      },
      feeVolume: {
        ID: 0,
        ReceivePlaceID: 1,
        VolumeFrom: 0,
        VolumeTo: 0,
        Amount: 0
      }
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.prices.loading,
      selectedItem: (state) => state.prices.selected,
      stores: state => state.commons.stores,
      customers: state => _.map(state.customer.master.data, (item) => {
                return {
                    ...item,
                    'FilterName': item.CustomerCode ? item.CustomerCode : item.Username
                }
            }),
      configuration: state => state.configuration.selected
    }),
    modifyId() {
      return this.$route.params.Pid;
    },
    product_type_list() {
      return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
    },
  },
  watch: {
    modifyId() {
      this.refreshData();
    },
  },
  methods: {
    refreshData() {
        this.getCustomerList();
        if (this.modifyId > 0) {
            this.$store.dispatch("prices/getDetail", this.modifyId);
        } else {
            this.$store.commit("prices/setDetail", {
            data: {
                ID: 0,
                Name: "",
                Status: 0,
                Global: 0,
            },
            });
        }
    },
    saveData() {
      this.$store.dispatch("prices/setDetail", {
        data: {
          ...this.selectedItem,
          ModifiedBy: this.$store.state.authentication.user.username,
        },
        id: this.selectedItem.ID,
      });
      window.getApp.$emit("APP_NAVIGATE", "PricesList");
    },
    onCreateFeeWeight() {
        this.$store.dispatch("feeWeight/setDetail", {
            data: {
                ...this.feeWeight,
                PriceID: this.modifyId,
                ModifiedBy: this.$store.state.authentication.user.username
            },
            id: this.feeWeight.ID
        });
        this.dialogNewFeeWeight = false;
    },
    onCreateFeeVolume() {
        this.$store.dispatch("feeVolume/setDetail", {
            data: {
                ...this.feeVolume,
                PriceID: this.modifyId,
                ModifiedBy: this.$store.state.authentication.user.username
            },
            id: this.feeVolume.ID
        });
        this.dialogNewFeeVolume = false;
    },
    onUpdateCustomer() {
        this.$store.dispatch("customer/setCustomerDetail", {
            data: {PriceID: this.modifyId, Action: "add_price"},
            id: this.selectedCustomerID
        });
    },
    getCustomerList() {
        this.$store.dispatch("customer/getCustomerList", {
            is_master: 1,
            fields: ["ID", "Username", "CustomerCode"]
        });
    },
  },
  mounted() {
    this.refreshData();
    this.$store.dispatch("configuration/getDetail", 1);
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
  },
};
</script>